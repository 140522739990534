

export default {
  'main': [
    {
      name: 'Dashboard User',
      to: '/dashboard',
      keymenu: 'surattugas',
      icon: 'fas fa-th-large',
      permission: 'ALL',
      notifkey: null, 
      group: null,
    },
    { 
      name: 'Dashboard HR',
      to: '/admin-dashboard',
      keymenu: 'dashboard',
      icon: 'fas fa-chart-line',
      permission: 'DASHBOARD_EMPLOYEE_GRAPH',
      notifkey: null, 
      group: null,
    },
    {
      name: 'Kehadiran',
      to: '/attendance',
      keymenu: 'attendance',
      icon: 'fas fa-user-clock',
      permission: 'USER_ATTENDANCE',
      notifkey: null, 
      group: null ,
    },
    {
      name: 'Break',
      to: '/break',
      keymenu: 'break',
      icon: 'fas fa-pause-circle',
      permission: 'USER_BREAK',
      notifkey: null, 
      group: null,
    }, 
    {
      name: 'Kunjungan',
      to: '/visitation',
      keymenu: 'visitation',
      icon: 'fas fa-map-marked-alt', 
      permission: 'USER_VISITATION',
      notifkey: null, 
      group: null,
    },

    {
      name: 'Cuti',
      to: '/timeoff',
      keymenu: 'visitation',
      icon: 'fas fa-business-time',
      permission: 'USER_TIME_OFF',
      notifkey: null, 
      group: null,
    },

    {
      name: 'Reimburse',
      to: '/reimburse',
      keymenu: 'reimburse',
      icon: 'fas fa-scroll',
      permission: 'USER_REIMBURSE',
      notifkey: 'MENU_REIMBURSE',
      group: null
    },

    {
      name: 'Surat Tugas',
      to: '/surattugas',
      keymenu: 'surattugas',
      icon: 'fas fa-envelope-open-text',
      permission: 'USER_ASSIGNMENT_LETTER',
      notifkey: 'MENU_LETTER', 
      group: null
    },

    {
      name: 'Approval',
      to: '/approval',
      keymenu: 'surattugas',
      icon: 'fas fa-check-double',
      permission: 'USER_APPROVAL',
      notifkey: 'MENU_APPROVAL', 
      group: null
    },

    {
      name: 'Kontak Karyawan',
        to: '/information/contactnumber',
      keymenu: 'surattugas',
      icon: 'fas fa-address-book',
      permission: 'USER_CONTACT_BOOK',
      notifkey: null, 
      group: null
    }, 

    
    {
      name: 'Finance CR',
        to: '/finance/reimburse',
      keymenu: 'surattugas',
      icon: 'fas fa-file-invoice-dollar',
      permission: 'CASH_REIMBURSE_VIEW',
      notifkey: 'ADMIN_FINANCE_REIMBURSE', 
      group: 'FAT'
    },

    {
      name: 'Finance CA',
        to: '/finance/cashadvance',
      keymenu: 'surattugas',
      icon: 'fas fa-cash-register',
      permission: 'CASH_ADVANCE_VIEW',
      notifkey: 'ADMIN_FINANCE_CADVANCE', 
      group: 'FAT'
    },
    {
      name: 'Payment Voucher',
        to: '/paymentvoucher',
      keymenu: 'surattugas',
      icon: 'fas fa-file-invoice',
      permission: 'PAYMENT_VOUCHER_VIEW',
      notifkey: 'ADMIN_PAYMENT_VOUCHER', 
      group: 'FAT'
    },  
    {
      name: 'Petty Cash',
        to: '/petty-cash',
      keymenu: 'surattugas',
      icon: 'fas fa-money-bill',
      permission: 'PETTYCASH_ACCOUNT_VIEW',
      notifkey: null, 
      group: 'FAT'
    }, 
    {
      name: 'Karyawan',
        to: '/employee',
      keymenu: 'surattugas',
      icon: 'fas fa-users-cog',
      permission: 'EMPLOYEE_VIEW',
      notifkey: null, 
      group: 'HRGA'
    }, 
    {
      name: 'Kontrak Karyawan',
        to: '/employee/contract',
      keymenu: 'surattugas',
      icon: 'fas fa-file-signature',
      permission: 'EMPLOYEE_CONTRACT_VIEW',
      notifkey: null, 
      group: 'HRGA'
    }, 
    {
      name: 'Surat Peringatan',
        to: '/reprimand',
      keymenu: 'surattugas',
      icon: 'fas fa-envelope-open-text',
      permission: 'REPRIMAND_LETTER_VIEW',
      notifkey: null, 
      group: 'HRGA'
    },  

    {
      name: 'Asset Serah Terima',
        to: '/handover',
      keymenu: 'surattugas',
      icon: 'fas fa-boxes',
      permission: 'ASSET_HANDOVER_VIEW',
      notifkey: null, 
      group: 'HRGA'
    },
    {
      name: 'Manager On Duty',
        to: '/mod/',
      keymenu: 'surattugas',
      icon: 'fas fa-user-tie',
      permission: 'MANAGER_ON_DUTY_VIEW',  
      notifkey: null, 
      group: 'HRGA'
    },
    {
      name: 'Pengumuman',
        to: '/announcement',
      keymenu: 'surattugas',
      icon: 'fas fa-bullhorn',
      permission: 'ANNOUNCEMENT_VIEW',
      notifkey: null, 
      group: 'HRGA'
    },  
    {
      name: 'Rekap Kehadiran',
        to: '/attendance/admin/report',
      keymenu: 'surattugas',
      icon: 'fas fa-file-contract',
      permission: 'ATTENDANCE_REPORT_VIEW',
      notifkey: null, 
      group: 'HRGA',
    },
    {
      name: 'Laporan Kehadiran',
        to: '/attendance/admin/dailyreport',
      keymenu: 'surattugas',
      icon: 'fas fa-history',  
      permission: 'ATTENDANCE_DAILY_VIEW',
      notifkey: null, 
      group: 'HRGA'
    },
    {
      name: 'Laporan Cuti',
        to: '/attendance/admin/timeoffreport',
      keymenu: 'surattugas',
      icon: 'fas fa-clipboard',
      permission: 'TIME_OFF_REPORT_VIEW',
      notifkey: null, 
      group: 'HRGA'
    }, 
    {
      name: 'Laporan Kunjungan',
        to: '/visitation/admin/report',
      keymenu: 'surattugas',
      icon: 'fas fa-map', 
      permission: 'VISITATION_REPORT_VIEW',
      notifkey: null, 
      group: 'HRGA'
    },
    {
      name: 'Laporan Break',
      to: '/break/admin/breakreport',
      keymenu: 'surattugas',
      icon: 'fas fa-file-alt',   
      permission: 'BREAK_REPORT_VIEW',
      notifkey: null, 
      group: 'HRGA',
    },  
    {
      name: 'Laporan Surat Tugas',
        to: '/surattugas/manage',
      keymenu: 'surattugas',
      icon: 'fas fa-mail-bulk',
      permission: 'ASSIGNMENT_LETTER_REPORT_VIEW',
      notifkey: null, 
      group: 'HRGA',
    },  
    {
      name: 'Laporan Ganti Shift',
        to: '/exchangeshift/report',
      keymenu: 'surattugas',
      icon: 'fas fa-exchange-alt tnt-gray-color rotate90',   
      permission: 'TIME_SLOT_CHANGE_REPORT_VIEW',
      notifkey: null, 
      group: 'HRGA'
    },  
    {
      name: 'Laporan Lembur',
        to: '/overtime/report',
      keymenu: 'surattugas',
      icon: 'fas fa-hourglass-half',   
      permission: 'OVERTIME_REPORT_VIEW',
      notifkey: null, 
      group: 'HRGA'
    },  
    {
      name: 'Laporan Pengajuan Keterlambatan',
        to: '/attendancerequest/report',
      keymenu: 'surattugas',
      icon: 'fas fa-clock',   
      permission: 'ATTENDANCE_REQUEST_VIEW',
      notifkey: null, 
      group: 'HRGA'
    }, 
    {
      name: 'Set. Shift',
        to: '/attendance/admin/shift',
      keymenu: 'surattugas',
      icon: 'fas fa-calendar-week', 
      permission: 'SHIFT_SETTING',
      notifkey: null, 
      group: 'HRGA'
    },  
    {
      name: 'Set. Cuti',
        to: '/cuti/admin/report',
      keymenu: 'surattugas',
      icon: 'fas fa-briefcase', 
      permission: 'TIME_OFF_SETTING',
      notifkey: null, 
      group: 'HRGA'
    },  
    {
      name: 'Konfig. Pengupahan',
        to: '/payroll/setting',
      keymenu: 'surattugas',
      icon: 'fas fa-money-check', 
      permission: 'PAYROLL_CONFIGURATION',
      notifkey: null, 
      group: 'HRGA'
    },  
    
    {
      name: 'Daftar Upah',
        to: '/payroll/list',
      keymenu: 'surattugas',
      icon: 'fas fa-money-check-alt', 
      permission: 'PAYROLL_GENERATE_VIEW',
      notifkey: null, 
      group: 'HRGA'
    },  
    {
      name: 'Pinjaman',
        to: '/loan',
      keymenu: 'surattugas',
      icon: 'fas fa-credit-card', 
      permission: 'LOAN_VIEW',
      notifkey: null, 
      group: 'HRGA'
    },  
    {
      name: 'Asset',
      
        to: '/asset',
      keymenu: 'surattugas',
      icon: 'fas fa-archive',
      permission: 'ASSET_VIEW',
      notifkey: null, 
      group: 'Operasional'
    },
    {
      name: 'Asset Maintenance',
        to: '/maintenance',
      keymenu: 'surattugas',
      icon: 'fas fa-tools',
      permission: 'ASSET_MAINTENANCE_VIEW',
      notifkey: null, 
      group: 'Operasional'
    },
    {
      name: 'Vendor',
        to: '/vendor',
      keymenu: 'surattugas',
      icon: 'fas fa-warehouse',
      permission: 'VENDOR_VIEW',
      notifkey: null, 
      group: 'Operasional'
    },   
     
    {
      name: 'Set. Perusahaan',
        to: '/information/company',
      keymenu: 'surattugas',
      icon: 'fas fa-building',
      permission: 'COMPANY_SETTING',
      notifkey: null, 
      group: 'Pengaturan'
    },
    {
      name: 'Set. Kategori',
        to: '/information/category',
      keymenu: 'surattugas',
      icon: 'fas fa-cogs',
      permission: 'CATEGORY_SETTING',
      notifkey: null, 
      group: 'Pengaturan'
    },
    {
      name: 'Set. Kode Shift',
        to: '/information/shift',
      keymenu: 'surattugas',
      icon: 'fas fa-table',
      permission: 'TIME_SLOT_SETTING_VIEW',
      notifkey: null, 
      group: 'Pengaturan'
    },
    {
      name: 'Set. Divisi & Akses',
        to: '/information/division',
      keymenu: 'surattugas',
      icon: 'fas fa-tasks',
      permission: 'DIVISION_SETTING',
      notifkey: null, 
      group: 'Pengaturan'
    },
    {
      name: 'Set. Approval',
        to: '/information/settingapproval',
      keymenu: 'surattugas',
      icon: 'fas fa-project-diagram',
      permission: 'APPROVAL_SETTING_VIEW',
      notifkey: null, 
      group: 'Pengaturan'
    }, 
    {
      name: 'Set. Lokasi Kehadiran',
        to: '/company/location',
      keymenu: 'surattugas',
      icon: 'fas fa-map',
      permission: 'ATTENDANCE_LOCATION_SETTING',
      notifkey: null, 
      group: 'Pengaturan'
    },
    {
      name: 'Set. Tipe Cuti',
        to: '/company/timeoff-type',
      keymenu: 'surattugas',
      icon: 'fas fa-file-alt',
      permission: 'TIME_OFF_TYPE_SETTING',
      notifkey: null, 
      group: 'Pengaturan'
    }, 

    
    {
      name: 'Message History',
        to: '/messagehistory',
      keymenu: 'surattugas',
      icon: 'fas fa-mail-bulk',
      permission: 'MESSAGE_HISTORY_VIEW',
      notifkey: null, 
      group: 'Super'
    },

    {
      name: 'Set. Param',
        to: '/company/setting',
      keymenu: 'surattugas',
      icon: 'fas fa-cogs',
      permission: 'COMPANY_CONFIG_VIEW',
      notifkey: null, 
      group: 'Super'
    },
  ]
 
}
